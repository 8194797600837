$(function() {

	var headerHeight = $( ".header > .container" ).height();
  $('.navbar-wrapper').css( "height", $( ".navbar" ).height() );
  $('.navbar').affix({
        offset: {
          top: headerHeight
        }
  }); 

	$('#products > ul').affix({
	  offset: {
	    top: function () {
			var headerHeight2 = $( ".header" ).height();
      var sliderHeight = $( ".section--slider" ).height();
      var navbarHeight = $( ".navbar" ).height();
			var prnavOffset = headerHeight2 + sliderHeight - navbarHeight + 40;
	    	return prnavOffset
	    },
	    bottom: function () {
	    	var footerHeight = $( ".footer" ).outerHeight( true ) + 20;
	    	return footerHeight
	    }
	  }
	})

	$( window ).resize(function() {
		headerHeight = $( ".header > .container" ).height();
    headerHeight2 = $( ".header" ).height();
    sliderHeight = $( ".section--slider" ).height();
    navbarHeight = $( ".navbar" ).height();
		prnavOffset = headerHeight2 + sliderHeight - navbarHeight;
    footerHeight = $( ".footer" ).outerHeight( true ) + 20;
    $('.navbar').data('bs.affix').options.offset.top = headerHeight;
    if( $('#products > ul').length > 0 ) {
      $('#products > ul').data('bs.affix').options.offset.top = prnavOffset;
		  $('#products > ul').data('bs.affix').options.offset.bottom = footerHeight;
    }
  });	

    $(".fancybox").fancybox();
    $(".fancybox-thumbs").fancybox({
        prevEffect: 'none',
        nextEffect: 'none',
        openEffect: 'elastic',
        openSpeed: 150,
        closeEffect: 'elastic',
        closeSpeed: 150,
        closeClick: true,
        helpers: {
            title: {
                type: 'outside'
            },
            thumbs: {
                width: 50,
                height: 50
            }
        }
    });

    $("#main-carousel").owlCarousel({
      items: 1,
      loop: true,
      nav: true,
      dots: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true   
    });
  var docHeight = $(window).height();
   var footerHeight = $('.footer').height();
   var footerTop = $('.footer').position().top + footerHeight;

   if (footerTop < docHeight) {
    $('.footer').css('margin-top', 10+ (docHeight - footerTop) + 'px');
   }
});
